import { useTranslation } from 'react-i18next'
import { useParams } from 'next/navigation'
import searchIcon from './product-icon/search.svg'
import webGptIcon from './product-icon/webgpt.svg'
import audioIcon from './product-icon/audio.svg'
import codeIcon from './product-icon/code.svg'
import machineIcon from './product-icon/machine.svg'

export default function useMenu() {
  const { t } = useTranslation()
  const params = useParams()
  const lang = params.lang as string
  return [
    {
      name: t('common.menus.product'),
      href: '',
      children: [
        { name: t('common.menus.search'), href: `/${lang}/pages/search`, icon: searchIcon },
        { name: t('common.menus.audio'), href: `/${lang}/pages/audio`, icon: audioIcon },
        { name: t('common.menus.machine'), href: `/${lang}/pages/machine`, icon: machineIcon },
        { name: t('common.menus.codeGenerator'), href: `/${lang}/pages/codeGenerator`, icon: codeIcon },
        { name: t('common.menus.gpt'), href: `/${lang}/pages/gpt`, icon: webGptIcon },
      ],
    },
    { name: t('common.menus.blog'), href: `/${lang}/blog` },
    { name: t('common.menus.about'), href: `/${lang}/pages/about` },
  ]
}
