export const fallbackLng = 'zh'
export const languages = [fallbackLng, 'en']
export const defaultNS = 'translation'
export const LOCALE_COOKIE_NAME = 'i18next'

export type Locale = typeof languages[number]

export function getOptions(lng = fallbackLng, ns = defaultNS) {
  return {
    // debug: true,
    supportedLngs: languages,
    // preload: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  }
}
