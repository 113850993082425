import { Menu, Transition } from '@headlessui/react'
import { usePathname } from 'next/navigation'
import React, { Fragment, useRef } from 'react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import cn from 'classnames'
import Image from 'next/image'
import { isMobile } from 'react-device-detect'

const DropDown = ({ name, list, position, itemsClass }: { name: string; list?: any[]; position?: string; itemsClass?: string }) => {
  const pathname = usePathname()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropdownRef = useRef<HTMLParagraphElement>(null)
  const timeoutDuration = 200
  let timeout: any
  const openMenu = () => buttonRef?.current?.click()
  const closeMenu = () =>
    dropdownRef?.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      }),
    )
  const onMouseEnter = (closed?: boolean) => {
    clearTimeout(timeout)
    closed && openMenu()
  }
  const onMouseLeave = (open: boolean) => {
    open && (timeout = setTimeout(() => closeMenu(), timeoutDuration))
  }

  return (
    <Menu as="div" className="relative ml-3 inline-flex items-center h-full">
      {({ open }) => (
        <>
          <div
            className='inline-flex h-full'
            onMouseOver={(e) => {
              if (!isMobile)
                onMouseEnter(!open)
            }}
            onMouseOut={() => onMouseLeave(open)}>
            <Menu.Button className="flex h-full  items-center text-sm outline-none" ref={buttonRef}>
              <div className='flex items-center flex-shrink-0 text-gray-900'>
                {name}
                <ChevronDownIcon
                  className={cn('ml-1 h-3 w-4 text-violet-500 transition-all ease-in', open ? 'rotate-180' : '')}
                  aria-hidden="true"
                />
              </div>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className={cn('absolute top-14 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none', 'before:content-[\'\'] before:h-3 before:w-3 before:block before:border-l before:border-t before:border-gray-200 before:rotate-45 before:bg-white before:absolute  before:-top-1.5 before:rounded-sm', position === 'right' ? 'right-0 before:right-5' : 'left-0 before:left-5', itemsClass)}
              ref={dropdownRef}
              onMouseEnter={() => onMouseEnter()}
              onMouseLeave={() => onMouseLeave(open)}
              static
            >
              {list?.map((item, index) => {
                return (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <Link href={item.href}
                        key={index}
                        className={cn(
                          active ? 'bg-gray-100' : '',
                          'flex w-full px-4 py-2 text-sm text-gray-700 justify-between',
                        )}>
                        <div className='flex'>
                          {item.icon && <Image
                            alt= {item.name}
                            src={item.icon}
                            className='mr-2'
                            width={16}
                          />}
                          {item.name}
                        </div>
                        {
                          pathname === item.href
                            ? (
                              <CheckIcon className='w-4 h-4' />
                            )
                            : (<></>)
                        }
                      </Link>
                    )}
                  </Menu.Item>
                )
              })}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default React.memo(DropDown)
