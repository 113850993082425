const translation = {
  menus: {
    completion: 'Completion',
    blog: 'blog',
    product: 'Product',
    about: 'About',
    search: 'AI Search',
    gpt: 'Web ChatGPT',
    audio: 'AI Speech Synthesis',
    codeGenerator: 'Code Assist',
    machine: 'AI all-in-one machine',
  },
  index: {
    title: 'AI + Enterprise',
    desc0: 'Deeply integrating AI technology with enterprise needs<br/>Let AI become your information steward',
    desc1: 'Intelligent search, ChatGPT, and speech synthesis -- <br />our AI solutions help enterprises boost efficiency, enhance user experience, and innovate.',
    desc2: 'We offer comprehensive solutions to empower enterprises for digital transformation and future leadership',
    contactUs: 'Contact Us',
    watchVideo: 'Watch Video',
    search: 'Retrieval Enhancement',
    searchDesc: 'Document retrieval and Q&A services based on big models and knowledge bases. Efficiently retrieve document information, accurately answer specialized questions, and provide domain knowledge for big models',
    searchDesc1: 'It has the following features:',
    feat1: 'Knowledge Base Q&A',
    feat1Desc: 'Answer questions based on document content through the big model, or create a knowledge base to bring together multiple documents and cover domain knowledge with one question. Supports massive documents in multiple formats into the library, such as PDF, Word, HTML and so on.',
    feat2: 'Locate the original source',
    feat2Desc: 'In the face of massive technical documents and rules and regulations, using document Q&A, you can quickly retrieve the required knowledge and easily locate the source of the content.',
    feat3: 'Document Summarization',
    feat3Desc: 'When there are too many documents that are difficult to read through, you can use Document Summarization to automatically generate a summary of the document and quickly understand the content of the document.',
    feat4: 'Free model switching',
    feat4Desc: 'Supports GPT and privately trained models, allowing you to choose the right model for different needs and scenarios.',
    feat5: 'Data security',
    feat5Desc: 'Support the customization of MFP to ensure data security',
    audio: 'Voice Cloning',
    audioDesc1: 'Using advanced AI algorithms to generate human-like speech, the text is transformed into a natural spoken language.',
    audioDesc2: 'It replicates the subtleties of human speech, including intonation and articulation, making it ideally suited for a variety of applications, including virtual assistants, customer service automation, video games, language learning and content creation.',
    audioDesc3: 'This cutting-edge technology enhances the user experience, increases accessibility and simplifies the content creation process.',
    AIMachine: 'AI All-in-One Machine',
    AIMachineDesc: 'AI All-in-One Machine provides hardware and software for the private delivery of large models to customers in finance, government, education, manufacturing, fund, internet and other industries and large model enterprises to ensure customer data and information security.AI All-in-One Machine is equipped with the ability to deliver from the bottom layer of AI arithmetic hardware, system, storage, AI large models and the upper layer of multiple AI applications. The All-in-One PC can be deployed and delivered out-of-the-box at the customer\'s IDC or on a cloud service with a single click. Safe and convenient delivery of services such as AI training and inference.',
    AIMachine1: 'Retrieval Enhanced Q&A',
    AIMachine1Desc: 'Retrieval Enhanced Q&A is a document retrieval and Q&A service based on big models and knowledge bases, which can efficiently retrieve document information, accurately answer professional questions, and provide domain knowledge for big models.',
    AIMachine2: 'Privatized ChatGPT',
    AIMachine2Desc: 'See the introduction to web chatgpt for details.',
    AIMachine3: 'Efficient Office AI Suite',
    AIMachine3Desc: 'Support more than 100 office AI suite templates to help your employees work more efficiently.',
    AIMachine4: 'AI Big Models',
    AIMachine4Desc: 'All-in-one supports mainstream open source base big models: ChatGLM3, Qwen, Baichuan2, LLaMA-2,Falcon and other mainstream open source general big models.',
    code: 'Code Assist Programming',
    codeDesc: 'Code Assisted Programming is an AI code assistant designed to provide developers with intelligent insights and automation to improve productivity and code quality. It can help developers write code faster and more accurately by analyzing the code context and providing real-time suggestions and hints for code in the IDE to improve developer productivity.',
    code1: 'Code Generation',
    codeDesc1: 'Auto-completes code based on context, converts comments to code, auto-fills duplicate code, auto-completes tests, and can switch between multiple alternatives.',
    code2: 'Multiple Programming Languages',
    codeDesc2: 'Python, Typescript (Javascript), Java, Golang, C++, C# and Bash.',
    code3: 'Multiple leading IDEs',
    codeDesc3: 'Visual Studio Code, JetBrains IDEs, Neovim.',
    code4: 'Data Security',
    codeDesc4: 'Large models can be privately deployed without worrying about internal code data leakage in the enterprise.',
  },
}

export default translation
