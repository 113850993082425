const translation = {
  lang: {
    zh: '中文',
    en: 'English',
  },
  notFond: '404',
  notFoundDesc: '抱歉，你所访问的页面不存在',
  backHome: '返回首页',
}

export default translation
