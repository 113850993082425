'use client'

import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { APP_INFO } from '@/config'

const Footer = (
  {
    cl,
  }: { cl?: string },
) => {
  const { t } = useTranslation()
  return (
    <>
      {/* copyright  */}
      <div className={cn('md:ml-[var(--layout-left)] justify-center md:w-content py-6 bottom-4 flex space-x-2 text-gray-400 font-normal text-xs', cl, 'border-t border-slate-200')}>
        <div className="">Copyright © {(new Date()).getFullYear()} {APP_INFO.title}</div>
        {APP_INFO.privacy_policy && (
          <>
            <div>·</div>
            <div>{t('app.generation.privacyPolicyLeft')}
              <a
                className='text-gray-500'
                href={APP_INFO.privacy_policy}
                target='_blank'>{t('app.generation.privacyPolicyMiddle')}</a>
              {t('app.generation.privacyPolicyRight')}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Footer
