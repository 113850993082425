'use client'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import i18next from 'i18next'
import { setLocaleOnClient } from '@/i18n/client'

export type II18nProps = {
  children: React.ReactNode
  lang: string
}
const runsOnServerSide = typeof window === 'undefined'

const I18N: FC<II18nProps> = ({
  children,
  lang,
}) => {
  // 首次访问先根据用户设备协商 语言 或 者默认中文
  if (runsOnServerSide)
    i18next.changeLanguage(lang)

  useEffect(() => {
    setLocaleOnClient(lang)
  }, [lang])

  return (
    <>
      {children}
    </>
  )
}

export default I18N
