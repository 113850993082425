const translation = {
  menus: {
    completion: '检索',
    blog: '动态',
    product: '产品',
    about: '关于',
    search: '检索增强',
    gpt: 'Web chatGPT',
    audio: '语音合成/视频',
    codeGenerator: '代码辅助编程',
    machine: 'AI一体机',
  },
  index: {
    title: 'AI + 企业',
    desc0: '我们致力于将AI技术与企业需求深度融合<br/>让AI成为您的信息管家',
    desc1: '以智能检索、ChatGPT、语音合成等强大功能，帮助企业提升运营效率、优化用户体验、驱动业务创新',
    desc2: '通过AI一体机，我们为企业提供一站式解决方案，助力企业实现数字化转型，引领智能时代',
    contactUs: '联系我们',
    watchVideo: '观看视频',
    search: '检索增强',
    searchDesc: '基于大模型和知识库的文档检索和问答服务。高效地检索文档信息，准确地回答专业问题，为大模型提供领域知识',
    searchDesc1: '它具有以下功能：',
    feat1: '知识库问答',
    feat1Desc: '通过大模型根据文档内容回答问题，或者创建知识库汇集多个文档，一次提问覆盖领域知识。支持多种格式的海量文档入库，如PDF、Word、HTML等。',
    feat2: '原文定位',
    feat2Desc: '面对海量的技术文档和规章制度，使用文档问答，可以快速地检索所需的知识，方便地定位内容的出处。',
    feat3: '文档总结',
    feat3Desc: '当文档过多难以通读时，可以使用文档总结，自动地生成文档的概要，快速地了解文档的内容。',
    feat4: '模型自由切换',
    feat4Desc: '支持GPT和私有化训练的模型，可以根据不同的需求和场景选择合适的模型。',
    feat5: '数据安全',
    feat5Desc: '支持一体机的定制，保证数据安全',
    audio: '语音克隆',
    audioDesc1: '利用先进的人工智能算法生成类似人类的语音，将文本转化为自然的口语。',
    audioDesc2: '它复制了人类语音的微妙之处，包括语调和发音，使其非常适合各种应用，包括虚拟助手、客户服务自动化、视频游戏、语言学习和内容创作。',
    audioDesc3: '这一前沿技术提升了用户体验，增强了可访问性，并简化了内容制作过程。',
    AIMachine: 'AI一体机',
    AIMachineDesc: '提供软硬件的AI一体机，面向金融、政府、教育、制造、基金、互联网等行业客户及大模型企业的大模型私有化交付场景，确保客户的的数据和信息安全。AI一体机具备从底层的AI算力硬件、系统、存储、AI大模型和上层多AI应用能力。一体机在客户的IDC开箱即用或在云服务上一键部署交付。安全便捷的提供AI训练和推理等服务。',
    AIMachine1: '检索增强问答',
    AIMachine1Desc: '检索增强问答是一种基于大模型和知识库的文档检索和问答服务，它能够高效地检索文档信息，准确地回答专业问题，并为大模型提供领域知识。',
    AIMachine2: '私有化ChatGPT',
    AIMachine2Desc: '详细参见web chatgpt介绍。',
    AIMachine3: '高效办公AI套件',
    AIMachine3Desc: '支持100多种办公AI套件模板，帮助您的员工更高效地工作。',
    AIMachine4: 'AI大模型',
    AIMachine4Desc: '一体机支持主流的开源基座大模型：ChatGLM3、Qwen、Baichuan2、LLaMA-2,Falcon等主流的开源通用大模型。',
    code: '代码辅助编程',
    codeDesc: '代码辅助编程是一款人工智能代码助手，旨在为开发者提供智能洞察和自动化以提高生产力和代码质量。它可以通过分析代码上下文，在IDE中提供代码实时建议和提示，以帮助开发者更快、更准确地编写代码，提高开发者的生产力。',
    code1: '代码生成',
    codeDesc1: '根据上下文自动补全代码，将注释转换为代码，自动填充重复代码，自动补全测试，并可以切换多个替代方案。',
    code2: '多种编程语言',
    codeDesc2: 'Python、Typescript (Javascript)、Java、Golang、C++、C#和Bash等。',
    code3: '多种主流IDE',
    codeDesc3: 'Visual Studio Code、JetBrains IDEs、Neovim。',
    code4: '数据安全',
    codeDesc4: '可私有化部署大模型，不用担心企业内部代码数据泄露。',
  },
}

export default translation
