const translation = {
  lang: {
    zh: '中文',
    en: 'English',
  },
  notFond: 'Page not found',
  notFoundDesc: 'Sorry, we couldn’t find the page you’re looking for.',
  backHome: 'Go back home',
}

export default translation
