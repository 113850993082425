import type { AppInfo } from '@/types/app'
export const APP_ID = '123456'

export const APP_INFO: AppInfo = {
  title: 'Flow',
  description: 'App description',
  // privacy_policy: 'privacy_policy',
}

export const API_PREFIX = '/api'

export const LOCALE_COOKIE_NAME = 'locale'

export const DEFAULT_VALUE_MAX_LEN = 48
