'use client'

import React from 'react'
import { useParams, usePathname } from 'next/navigation'
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'
import cn from 'classnames'
import DropDown from '../base/drop-down'
import AppIcon from './app-icon'
import useMenu from './useMenu'
import { languages } from '@/i18n/settings'
import { APP_INFO } from '@/config'

const Header = () => {
  const pathname = usePathname()
  const params = useParams()
  const lang = params.lang as string
  const { t } = useTranslation()
  const menu = useMenu()

  const _languages = languages.map((l) => {
    return {
      href: pathname.replace(lang, l),
      name: t(`app.lang.${l}`),
    }
  })

  return (
    <Disclosure as="nav" className="bg-white shadow-sm">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative z-20">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <Link href={`/${lang}`} className="flex flex-shrink-0 items-center">
                  <AppIcon size="medium" className='' />
                  <div className="ml-1.5 text-xl text-gray-800 font-bold">{APP_INFO.title}</div>
                </Link>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {menu.map(item => (
                    item.href
                      ? (<Link
                        key={item.name}
                        href={item.href}
                        className={cn(
                          pathname === item.href
                            ? 'border-slate-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                        )}
                        aria-current={pathname === item.href ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>)
                      : (
                        <div key={item.name} className="inline-flex items-center -mr-1">
                          <DropDown name={item.name} list={item?.children} />
                        </div>
                      )
                  ))}
                </div>
              </div>

              <div className="-mr-2 flex items-center">
                <div className="sm:ml-6 flex h-full items-center mr-1">
                  <DropDown name={t(`app.lang.${lang}`)} list={_languages} position='right' itemsClass='w-32' />
                </div>
                <Disclosure.Button className="sm:hidden inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                  <span className="sr-only">Open main menu</span>
                  {open
                    ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    )
                    : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          {/* 移动端菜单栏 */}
          <Disclosure.Panel className="sm:hidden absolute w-full bg-white z-10 shadow-md">
            <div className="space-y-1 pt-2 pb-3">
              {menu.map(item => (
                item.href
                  ? (<Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={cn(
                      pathname === item.href
                        ? 'bg-slate-50 border-slate-500 text-slate-700'
                        : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                      'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                    )}
                    aria-current={pathname === item.href ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>)
                  : (
                    <div key={item.name}>
                      <div className='border-transparent text-gray-600 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'>
                        {item.name}
                      </div>
                      {item?.children?.map((item, index) => {
                        return (
                          <Link href={item.href}
                            key={index}
                            className={cn(
                              'flex w-full px-8 py-2 text-sm text-gray-700 justify-between',
                            )}>
                            <div className='flex'>
                              <Image
                                alt="Mountains"
                                src={item.icon}
                                width={17}
                                className='mr-2'
                              />
                              {item.name}
                            </div>
                            {
                              pathname === item.href
                                ? (
                                  <CheckIcon className='w-4 h-4' />
                                )
                                : (<></>)
                            }
                          </Link>
                        )
                      })}
                    </div>
                  )
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default React.memo(Header)
