'use client'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Cookies from 'js-cookie'
import LanguageDetector from 'i18next-browser-languagedetector'
import commonEn from './lang/common.en'
import commonZh from './lang/common.zh'
import appEn from './lang/app.en'
import appZh from './lang/app.zh'
import type { Locale } from './settings'
import { LOCALE_COOKIE_NAME, getOptions } from './settings'

const resources = {
  en: {
    translation: {
      common: commonEn,
      app: appEn,
    },
  },
  zh: {
    translation: {
      common: commonZh,
      app: appZh,
    },
  },
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator'],
    },
    resources,
  })

export const setLocaleOnClient = (locale: Locale, reloadPage = false) => {
  Cookies.set(LOCALE_COOKIE_NAME, locale, { expires: 31 })
  i18next.changeLanguage(locale)
  reloadPage && location.reload()
}
